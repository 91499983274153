import ApiService from "@/core/services/api.service";
import {exportData} from "@/core/services/common.service";
import {EXPORT_ERROR_LIST} from "@/core/services/store/report-error/reportError.module";
import {EXPORT_CUSTOMERS} from "../user/users.module";
import writeXlsxFile from 'write-excel-file'
import {CREATE_BRANCH} from "../center/branch.module";

const _ = require('lodash');
export const CREATE_CLASS = "create_class";
export const UPDATE_CLASS = "update_class";
export const GET_TEACHERS_OR_TUTORS = "get_teachers_or_tutors";
export const GET_CLASSES = "get_classes";
export const DELETE_CLASS = "delete_class";
export const GET_ClASS_BY_ID = "get_class_by_id";
export const EXPORT_CLASS = "export-class";
export const EXPORT_CLASS_V2 = "export-class-v2";
export const CHECK_FILE_IMPORT_EXCEL = "notification-app/preview";
export const IMPORT_EXCEL_NOTIFICATION = "notification-app/send";
export const GET_CLASS_LIST_ACTIVE = "classroom/get-list-active";
export const GET_STUDENTS_BY_CLASS = "student-by-class";
export const CREATE_NOTIFICATION_ERROR = "notification-app/send-by-classroom";
//Rooms
export const CREATE_ROOM = "create_room";
export const GET_ROOMS = "get_rooms";
export const GET_ROOM_EMPTY = "get_room_empty";
export const DELETE_ROOM = "delete_room";
export const GET_ROOM_BY_ID = "get_room_by_id";
export const UPDATE_ROOM = "update_room";
export const LIST_REQUEST_OFF_LESSON = "list-off-lesson";
export const APPROVE_OFF_LESSON = 'approve-off-lesson';
export const APPROVE_LIST_OFF_LESSON = 'approve-list-off-lesson';
export const APPROVE_SINGLE_OFF_LESSON = 'approve-single-off-lesson';
export const DETAIL_OFF_LESSON = 'detail-off-lesson';
export const DETAIL_APPROVE_OFF_LESSON = 'detail-approve-off-lesson';
export const ATTENDANCE = 'attendance';
export const ACTION_ATTENDANCE = "classroom/action-attendance";
export const GET_OFF_LESSON = 'check-off-lesson';
export const LIST_CLASS_SCHEDULE = "list-class-schedule";
export const DELETE_PROPOSE = "delete-propose";
export const UPDATE_TIME_ACTIVE_WARNING = "classroom/update-time-active-rest-lesson";
export const GET_ALL_CLASS = "classroom/get-all-class";


//candidate
export const GET_ALL_CANDIDATE = "candidate";
export const GET_ALL_TRAINING = "classroom/get-list-tvv-training";
export const GET_ALL_COURSE_TVV  = "classroom/get-course-tvv";
export const GET_PROGRAM_TVV = "classroom/get-program-tvv";

//candidate-teacher
export const CANDIDATE_TEACHER = "teacher-candidate";
export const SHOW_CANDIDATE_TEACHER = "eacher-candidate/show";
export const LIST_CANDIDATE_TEACHER = "teacher-candidate/list";
export const ADD_CANDIDATE_TEACHER = "teacher-candidate/add";
export const UPDATE_CANDIDATE_TEACHER = "teacher-candidate/update";
export const EXTEND_CANDIDATE_TEACHER = "teacher-candidate/extend";
export const REMOVE_CANDIDATE_TEACHER = "teacher-candidate/remove";
export const APPROVE_CANDIDATE_TEACHER = "teacher-candidate/approve";
export const GET_ALL_TRAINERS = "teacher-candidate/trainers";
export const SHOW_CANDIDATE_TEACHER_BY_UUID = "teacher-candidate/show-by-uuid";
export const GET_COURSE_CANDIDATE_TEACHER = "teacher-candidate/get-course";

// lộ trình học
export const LIST_SCHEDULE_COURSE = 'game/test/schedule-course';
export const CREATE_SCHEDULE_COURSE = 'game/test/schedule-course/create';
export const UPDATE_SCHEDULE_COURSE = 'game/test/schedule-course/update';
export const SHOW_SCHEDULE_COURSE = 'game/test/schedule-course/show';
export const DETAIL_SCHEDULE_COURSE = 'game/test/schedule-course/detail';
export const EDIT_GROUP_QUESTION = 'game/test/schedule-course/edit-group-question';
export const DELETE_QUESTION = 'game/test/schedule-course/delete-question';
export const DELETE_GROUP_QUESTION = 'game/test/schedule-course/delete-group-question';
export const EDIT_QUESTION = 'game/test/schedule-course/edit-question';
export const ADD_GROUP_QUESTION = 'game/test/schedule-course/create-group-question';
export const ADD_QUESTION = 'game/test/schedule-course/create-question';
export const IMPORT_LO_TRINH = 'game/test/import/schedule';
export const IMPORT_TU_VUNG = 'game/test/import/vocab-practice';
export const UPLOAD_SLIDE_SCHEDULE = 'game/test/schedule/upload-slide';
export const UPLOAD_FILE_GOOGLE_SLIDE = 'game/test/schedule/upload-google-slide';
export const MULTIPART_UPLOAD_SLIDE_SCHEDULE = 'game/test/create-multipart-upload-slide';
export const MULTIPART_EDIT_SLIDE_SCHEDULE = 'game/test/edit-multipart-upload-slide';

const PATH_SLIDE = "slide-schedule/slide";
export const EDIT_SLIDE = 'slide-schedule/edit-slide';
export const SHOW_LIST_SLIDE = 'game/test/schedule/get-list-slide';
export const SHOW_ALL_LIST_SLIDE = 'SHOW_ALL_LIST_SLIDE';
export const REMOVE_SLIDE_BY_ID = 'REMOVE_SLIDE_BY_ID';
export const REMOVE_UPLOAD_SLIDE_SCHEDULE = 'game/test/schedule/remove-upload-slide';
export const REMOVE_SLIDE = 'game/test/schedule/remove-slide';

//Lộ trình học của 1 lớp
export const SCHEDULE_OF_CLASS = 'schedule-of-a-class';

//schedule job
export const GET_ALL_SCHEDULE_JOB = 'ScheduleJob/get-all-schedule-job';
export const CONNECT_SCHEDULE = 'ScheduleJob/connect-schedule-job';
export const DELETEE_SCHEDULE = 'ScheduleJob/delete-schedule-job';

// Sửa lỗi dạy học
export const CLASS_ERROR_LIST = "classrooms";
export const CLASS_FILTER = "classrooms/filter";
export const EXPORT_ERROR_CLASSROOM = "classrooms/error-export";
export const EXPORT_ERROR_CLASSROOM_STUDENT = "classrooms/error-export-student";
export const SCHEDULE_CLASS = "classrooms/schedule";
export const SCHEDULE_CLASS_LESSON = "classrooms/schedule-only-lesson";
export const ATTENDANCE_LESSION = "classrooms/attendance-lesson";
export const ACTION_ATTENDANCE_ADMIN = "classrooms/action-attendance";
export const ADMIN_HOMEWORK = "classrooms/exam/list-mark";
export const ADMIN_MARK_HOMEWORK = "classrooms/exam/mark";
export const ADMIN_GET_MARK_HOMEWORK = "get-mark-homework-student";
export const ADMIN_POST_MARK_HOMEWORK = "post-mark-homework-student";
export const ADMIN_REVIEW_HOMEWORK = "classrooms/exam/review";
export const CONTENT_HOMEWORK = "classrooms/schedule/homeworks";
export const RESULT_HOMEWORK = "classrooms/schedule/result";
export const LIST_COMMENT = "classrooms/exam/list-comment";
export const GET_AUTO_COMMENT = "classrooms/exam/get-auto-comment"
export const POST_COMMENT = "classrooms/exam/comment-lesson-upsert";
export const POST_COMMENT_STUDENT = "classrooms/exam/comment-lesson-upsert-v2"
export const GET_TIME_OPEN_SLIDE = "classrooms/exam/get_time_open_slide";
export const GET_LISTSTUDENT_COMMENT = "classrooms/exam/feedback-students"
export const SHOW_DETAIL_LEARNING_SCHEDULE = "classrooms/exam/schedule-show";

// Tặng quà buổi học
export const STUDENT_HAS_GIFT = "classrooms/gift/gift-of-lesson-class";
export const STUDENT_HAS_ATTENDANCE = "classrooms/gift/student-has-attendance";
export const GIVE_GIFT = "classrooms/gift/give-gift";

export const STUDYING = 0;
export const ABSENT = 1;
export const STOP_THROUGH = 2;
export const STOP_STUDYING = 3; //ngừng học

//mutations
export const SET_SCHEDULE_LEARNING = 'set-schedule-learning';
export const SET_SCHEDULE_LEARNING_ITEM = 'set-schedule-learning-item';

// Giáo trình
export const UPLOAD_CURRICULUM = 'game/test/curriculum/upload_curriculum';
export const UPDATE_CURRICULUM = 'game/test/curriculum/update_file_curriculum';
export const GET_CENTER_COURSE = 'game/test/curriculum/schedule_course';
// upload Data class
export const GET_DATA_UPLOAD_CLASS = "upload/get-data-upload";
export const UPLOAD_DATA_CLASS = "upload/upload-data-class";
export const DELETE_DATA_UPLOAD_CLASS = "upload/delete-data-class";
export const CREATE_MULTIPART_UPLOAD = "upload/create-multipart-upload";
// Notification
export const GET_LIST_NOTIFICATION = 'notification-history'
export const NOTIFICATION_RESEND = 'notification-history/resend'
// Team
export const LIST_MEMBER_CLASS = "classrooms/team/list-member";
export const DELETE_TEAM = "classrooms/team/delete-team";
export const LEAVE_TEAM = "classrooms/team/left";
export const GIFT = "classrooms/team/gift";
export const CHI_TIET_USER = "classrooms/team/detail-user";
export const STUDENT_RESULT_VOCAB = "classrooms/team/result-vocab";
export const STUDENT_RESULT_GRAMMAR = "classrooms/team/result-grammar";
export const DETAIL_ATTENDANCE = "classrooms/team/detail-attendance";
export const ALL_BADGES = "classrooms/team/all-badges";
export const SUB_HP = "classrooms/team/sub-hp";
export const ADD_MEMBER = "classrooms/team/add-member";
export const APPROVE_TEAM = "classrooms/team/approve";
export const UPDATE_CLANS_WHEN_APPROVE = "update_clans_when_approve";
export const ADMIN_MARK_HOMEWORK_KID = "classrooms/exam/kid/mark";
export const CREATE_TEAM = "classrooms/team/create";

// Nhận xét giảng viên
export const LIST_INSTRUCT = [
    {
        classify: 'Rất tốt',
        content: 'Thái độ học tập rất tích cực, năng lực học tập nổi trội, tham gia đóng góp xây dựng bài thường xuyên, chăm chỉ làm bài tập và thực hành.'
    },
    {
        classify: 'Tốt',
        content: 'Thái độ học tập tốt, có đóng góp xây dựng bài, hiểu bài và tiếp thu nhanh.'
    },
    {
        classify: 'Khá',
        content: 'Có chú ý lắng nghe bài giảng nhưng chưa tích cực tham gia các hoạt động của lớp.'
    },
    {
        classify: 'Không tốt',
        content: 'Chưa tập trung trong các hoạt động của lớp. Chưa làm đầy đủ bài tập về nhà.'
    },
    {
        classify: 'Rất không tốt',
        content: 'Chưa nghiêm túc học tập, không tham gia các hoạt động của lớp gây ảnh hưởng tới các bạn khác.'
    },

];

export const OPTION_CLASSIFY = [
    {
        id: 5,
        value: 'Rất tốt',
    },
    {
        id: 4,
        value: 'Tốt',
    },
    {
        id: 3,
        value: 'Khá',
    },
    {
        id: 2,
        value: 'Không tốt',
    },
    {
        id: 1,
        value: 'Rất không tốt',
    },
];

const state = {
    schedule_learnings: [],
    slide_uploaded: [],
};
const getters = {
    scheduleLearning(state){
        return state.schedule_learnings;
    },
};
const actions = {
    [DELETE_TEAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(DELETE_TEAM, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        })
    },
    [GET_TEACHERS_OR_TUTORS](context, payload) {
        return new Promise(resolve => {
            ApiService.query('user/get-teachers', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_CLASSES](context, payload) {
        return new Promise(resolve => {
            ApiService.query('classes', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                console.log(response)
            });
        })
    },
    [GET_ClASS_BY_ID](context, id) {
        return new Promise(resolve => {
            ApiService.get('classes', id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                console.log(response)
            });
        })
    },
    [CREATE_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('classes', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('classes', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_CLASS](context, id) {
        return new Promise(resolve => {
            ApiService.delete('classes', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [EXPORT_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_CLASS, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    [EXPORT_CLASS_V2](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(EXPORT_CLASS_V2, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response);
            });
        });
    },
    // Rooms
    [CREATE_ROOM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('rooms', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [GET_ROOMS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('rooms', payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [GET_ROOM_EMPTY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('rooms/empty', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [GET_ROOM_BY_ID](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get('rooms', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [UPDATE_ROOM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('rooms', payload.id, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_ROOM](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete('rooms', id).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [LIST_REQUEST_OFF_LESSON](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${LIST_REQUEST_OFF_LESSON}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [APPROVE_OFF_LESSON](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${APPROVE_OFF_LESSON}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

       // XOÁ ORDER DUYỆT BUỔI NGHỈ
    [DELETE_PROPOSE](context, payload) {
    return new Promise((resolve, reject) => {
        ApiService.post(`${DELETE_PROPOSE}`, payload).then(({data}) => {
            resolve(data);
        }).catch(({response}) => {
            reject(response)
        });
    })
    },

       // UPDATE TIME ACTIVE CÁC LỚP ĐÃ DUYỆT NGHI HOC
       [UPDATE_TIME_ACTIVE_WARNING](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPDATE_TIME_ACTIVE_WARNING}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
        },

    [LIST_SCHEDULE_COURSE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${LIST_SCHEDULE_COURSE}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    [CREATE_SCHEDULE_COURSE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`${CREATE_SCHEDULE_COURSE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_SCHEDULE_COURSE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`${UPDATE_SCHEDULE_COURSE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DETAIL_SCHEDULE_COURSE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.get(`game/test/schedule-course/detail`, payload).then(({data}) => {
                context.commit(SET_SCHEDULE_LEARNING, data.data)
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [SHOW_SCHEDULE_COURSE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`game/test/schedule-course/show/${payload.id}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [EDIT_GROUP_QUESTION](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`${EDIT_GROUP_QUESTION}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [GET_ALL_CANDIDATE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_ALL_CANDIDATE}`, payload)
            .then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [GET_ALL_TRAINING](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_ALL_TRAINING}`, payload)
              .then(({data}) => {
                  resolve(data);
              }).catch((response) => {
                reject(response)
            });
        })
    },
    [GET_ALL_COURSE_TVV](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_ALL_COURSE_TVV}`, payload)
                .then(({data}) => {
                    resolve(data);
                }).catch((response) => {
                reject(response)
            });
        })
    },
    [GET_PROGRAM_TVV](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_PROGRAM_TVV}`, payload)
              .then(({data}) => {
                  resolve(data);
              }).catch((response) => {
                reject(response)
            });
        })
    },
    [LIST_CANDIDATE_TEACHER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${CANDIDATE_TEACHER}`, payload)
            .then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [SHOW_CANDIDATE_TEACHER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${CANDIDATE_TEACHER}/${payload.id}`)
            .then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [ADD_CANDIDATE_TEACHER](context, payload) {
        return new Promise((resolve,reject) => {
            ApiService.post(`${CANDIDATE_TEACHER}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_CANDIDATE_TEACHER](context, payload) {
        return new Promise((resolve,reject) => {
            ApiService.put(`${CANDIDATE_TEACHER}/${payload.id}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [APPROVE_CANDIDATE_TEACHER](context, payload) {
        return new Promise((resolve,reject) => {
            ApiService.post(`${APPROVE_CANDIDATE_TEACHER}/${payload.id}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [EXTEND_CANDIDATE_TEACHER](context, payload) {
        return new Promise((resolve,reject) => {
            ApiService.post(`${EXTEND_CANDIDATE_TEACHER}/${payload.id_teacher}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [REMOVE_CANDIDATE_TEACHER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${CANDIDATE_TEACHER}`, payload.id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_GROUP_QUESTION](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`${DELETE_GROUP_QUESTION}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [DELETE_QUESTION](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`${DELETE_QUESTION}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [EDIT_QUESTION](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`${EDIT_QUESTION}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [ADD_GROUP_QUESTION](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`${ADD_GROUP_QUESTION}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [ADD_QUESTION](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`${ADD_QUESTION}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [IMPORT_LO_TRINH](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${IMPORT_LO_TRINH}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },

    [IMPORT_TU_VUNG](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${IMPORT_TU_VUNG}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [ATTENDANCE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query('classroom/schedule', payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [ACTION_ATTENDANCE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${ACTION_ATTENDANCE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    /**
     * api upload slide lộ trình
     * @param context
     * @param payload
     * @returns {Promise<unknown>}
     */
    [UPLOAD_SLIDE_SCHEDULE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPLOAD_SLIDE_SCHEDULE}/${payload.id}`, payload.params).then(({data}) => {
        //        context.commit(SET_SCHEDULE_LEARNING_ITEM, data.data);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [UPLOAD_FILE_GOOGLE_SLIDE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPLOAD_FILE_GOOGLE_SLIDE}`, payload).then(({data}) => {
        //        context.commit(SET_SCHEDULE_LEARNING_ITEM, data.data);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [MULTIPART_UPLOAD_SLIDE_SCHEDULE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(MULTIPART_UPLOAD_SLIDE_SCHEDULE, payload).then(({data}) => {
        //        context.commit(SET_SCHEDULE_LEARNING_ITEM, data.data);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [MULTIPART_EDIT_SLIDE_SCHEDULE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(MULTIPART_EDIT_SLIDE_SCHEDULE, payload).then(({data}) => {
        //        context.commit(SET_SCHEDULE_LEARNING_ITEM, data.data);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [SHOW_LIST_SLIDE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${SHOW_LIST_SLIDE}/${payload.id}`, payload.params).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [SHOW_ALL_LIST_SLIDE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(PATH_SLIDE, payload.params).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    
    [EDIT_SLIDE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${EDIT_SLIDE}`, payload).then(({data}) => {
        //        context.commit(SET_SCHEDULE_LEARNING_ITEM, data.data);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [REMOVE_SLIDE_BY_ID](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${PATH_SLIDE}`, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    /**
     * api remove slide lộ trình
     * @param context
     * @param id
     * @returns {Promise<unknown>}
     */
    [REMOVE_UPLOAD_SLIDE_SCHEDULE](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${REMOVE_UPLOAD_SLIDE_SCHEDULE}`, id).then(({data}) => {
                // context.commit(SET_SCHEDULE_LEARNING_ITEM, data.data);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [REMOVE_SLIDE](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${REMOVE_SLIDE}`, id).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    
    [LIST_CLASS_SCHEDULE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${LIST_CLASS_SCHEDULE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [DETAIL_OFF_LESSON](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${DETAIL_OFF_LESSON}`,payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [DETAIL_APPROVE_OFF_LESSON](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${DETAIL_APPROVE_OFF_LESSON}`,payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [APPROVE_LIST_OFF_LESSON](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${APPROVE_LIST_OFF_LESSON}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [APPROVE_SINGLE_OFF_LESSON](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${APPROVE_SINGLE_OFF_LESSON}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    /**
     * Lộ trình lớp học
     * Params api : - classroom_id (required, integer)
     * @param context
     * @param payload
     * @returns {Promise}
     */
    [SCHEDULE_OF_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${SCHEDULE_OF_CLASS}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },

    [GET_OFF_LESSON](context, idClass) {
        return new Promise(resolve => {
            ApiService.get(GET_OFF_LESSON, idClass).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [SCHEDULE_CLASS](context, idClass) {
        return new Promise(resolve => {
            ApiService.query(SCHEDULE_CLASS, idClass).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [SCHEDULE_CLASS_LESSON](context, idClass) {
        return new Promise(resolve => {
            ApiService.query(SCHEDULE_CLASS_LESSON, idClass).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CLASS_ERROR_LIST](context, payload) {
        return new Promise(resolve => {
            ApiService.query(CLASS_ERROR_LIST, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CLASS_FILTER](context, payload) {
        return new Promise(resolve => {
            ApiService.query(CLASS_FILTER, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [ATTENDANCE_LESSION](context, payload) {
        return new Promise(resolve => {
            ApiService.query(ATTENDANCE_LESSION, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [ACTION_ATTENDANCE_ADMIN](context, payload) {
        return new Promise(resolve => {
            ApiService.post(ACTION_ATTENDANCE_ADMIN, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [ADMIN_HOMEWORK](context, payload) {
        return new Promise(resolve => {
            ApiService.query(ADMIN_HOMEWORK, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [ADMIN_GET_MARK_HOMEWORK](context, payload) {
        return new Promise(resolve => {
            ApiService.query(ADMIN_MARK_HOMEWORK, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [ADMIN_POST_MARK_HOMEWORK](context, payload) {
        return new Promise(resolve => {
            ApiService.post(ADMIN_MARK_HOMEWORK, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [ADMIN_REVIEW_HOMEWORK](context, payload) {
        return new Promise(resolve => {
            ApiService.query(ADMIN_REVIEW_HOMEWORK, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CONTENT_HOMEWORK](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${CONTENT_HOMEWORK}/${payload.classroom_id}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [RESULT_HOMEWORK](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${RESULT_HOMEWORK}/${payload.classroom_id}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [STUDENT_HAS_GIFT](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${STUDENT_HAS_GIFT}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [STUDENT_HAS_ATTENDANCE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${STUDENT_HAS_ATTENDANCE}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GIVE_GIFT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${GIVE_GIFT}`, payload)
                .then((data) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [LIST_COMMENT](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`${LIST_COMMENT}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_AUTO_COMMENT](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`${GET_AUTO_COMMENT}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [POST_COMMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${POST_COMMENT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [POST_COMMENT_STUDENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${POST_COMMENT_STUDENT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [EXPORT_ERROR_CLASSROOM](context, payload) {
        return new Promise(resolve => {
            ApiService.query(EXPORT_ERROR_CLASSROOM, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [EXPORT_ERROR_CLASSROOM_STUDENT](context, payload) {
        return new Promise(resolve => {
            ApiService.query(EXPORT_ERROR_CLASSROOM_STUDENT, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    // giáo trình
    [UPLOAD_CURRICULUM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPLOAD_CURRICULUM}/${payload.id}`, payload.params).then(({data}) => {
        //        context.commit(SET_SCHEDULE_LEARNING_ITEM, data.data);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_CURRICULUM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPDATE_CURRICULUM}/${payload.id}`, payload.params).then(({data}) => {
        //        context.commit(SET_SCHEDULE_LEARNING_ITEM, data.data);
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_CURRICULUM](context,payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPDATE_CURRICULUM}/${payload.id}`, payload.params).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [GET_CENTER_COURSE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_CENTER_COURSE, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },

    //get data upload classes
    [GET_DATA_UPLOAD_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_DATA_UPLOAD_CLASS}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    // upload data class
    [UPLOAD_DATA_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${UPLOAD_DATA_CLASS}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    // delete data upload
    [DELETE_DATA_UPLOAD_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${DELETE_DATA_UPLOAD_CLASS}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },

    [CREATE_MULTIPART_UPLOAD](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${CREATE_MULTIPART_UPLOAD}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [GET_TIME_OPEN_SLIDE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_TIME_OPEN_SLIDE}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [GET_LISTSTUDENT_COMMENT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_LISTSTUDENT_COMMENT}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [SHOW_DETAIL_LEARNING_SCHEDULE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${SHOW_DETAIL_LEARNING_SCHEDULE}/${payload.schedule_id}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },

    [GET_ALL_SCHEDULE_JOB](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_ALL_SCHEDULE_JOB}`, payload)
            .then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [DELETEE_SCHEDULE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${DELETEE_SCHEDULE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [CONNECT_SCHEDULE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${CONNECT_SCHEDULE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [GET_LIST_NOTIFICATION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_LIST_NOTIFICATION}`, payload)
                .then(({data}) => {
                    resolve(data);
                }).catch((response) =>{
                reject(response)
            });
        })
    },
    [LIST_MEMBER_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${LIST_MEMBER_CLASS}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [NOTIFICATION_RESEND](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${NOTIFICATION_RESEND}/${id}`,).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [LEAVE_TEAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${LEAVE_TEAM}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [GIFT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${GIFT}`, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [CHI_TIET_USER](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${CHI_TIET_USER}`, id)
                .then((res) => {
                    resolve(res);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [STUDENT_RESULT_VOCAB](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${STUDENT_RESULT_VOCAB}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [STUDENT_RESULT_GRAMMAR](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${STUDENT_RESULT_GRAMMAR}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [DETAIL_ATTENDANCE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${DETAIL_ATTENDANCE}`, payload)
                .then((res) => {
                    resolve(res);
                })
                .catch(( { response })  => {
                    reject(response);
                });
        });
    },
    [ALL_BADGES](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${ALL_BADGES}`, id)
                .then((res) => {
                    resolve(res);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [SUB_HP](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SUB_HP, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [APPROVE_TEAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${APPROVE_TEAM}`, payload)
                .then((res) => {
                    // context.commit(UPDATE_CLANS_WHEN_APPROVE, payload);
                    resolve(res);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [ADD_MEMBER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${ADD_MEMBER}`, payload)
                .then((res) => {
                    // context.commit(UPDATE_USER_WHEN_ADD_MEMBER, payload, res.data.data);
                    resolve(res);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [GET_ALL_TRAINERS](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_ALL_TRAINERS, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [SHOW_CANDIDATE_TEACHER_BY_UUID](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${SHOW_CANDIDATE_TEACHER_BY_UUID}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [CHECK_FILE_IMPORT_EXCEL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(CHECK_FILE_IMPORT_EXCEL, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [IMPORT_EXCEL_NOTIFICATION](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(IMPORT_EXCEL_NOTIFICATION, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [GET_CLASS_LIST_ACTIVE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_CLASS_LIST_ACTIVE}`, payload)
                .then((res) => {
                    resolve(res.data);
                })
                .catch(( { response })  => {
                    reject(response);
                });
        });
    },
    [GET_STUDENTS_BY_CLASS](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_STUDENTS_BY_CLASS}/${id}`)
                .then((res) => {
                    resolve(res.data);
                })
                .catch(( { response })  => {
                    reject(response);
                });
        });
    },
    [CREATE_NOTIFICATION_ERROR](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(CREATE_NOTIFICATION_ERROR, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [GET_COURSE_CANDIDATE_TEACHER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${GET_COURSE_CANDIDATE_TEACHER}`, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                reject(response)
            });
        })
    },
    [ADMIN_MARK_HOMEWORK_KID](context, payload) {
        return new Promise(resolve => {
            ApiService.post(ADMIN_MARK_HOMEWORK_KID, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [GET_ALL_CLASS](context, payload) {
        return new Promise(resolve => {
            ApiService.query(GET_ALL_CLASS, payload).then(({data}) => {
                resolve(data);
            }).catch((response) => {
                console.log(response)
            });
        })
    },
    [CREATE_TEAM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(CREATE_TEAM, payload).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
};
const mutations = {
    [SET_SCHEDULE_LEARNING](state, data){
        state.schedule_learnings = data;
    },
    [SET_SCHEDULE_LEARNING_ITEM](state, schedule_learning){
        let schedule_learnings = state.schedule_learnings;
        let indexScheduleLearning = schedule_learnings.find(item => item.id === schedule_learning.id);
        indexScheduleLearning.media_slide = schedule_learning.media_slide;
    },
    [UPDATE_CLANS_WHEN_APPROVE](state, payload) {
        let clans = state.clans;
        let oldClan = clans.find((item) => {
            return item.id === payload.clan_id;
        });
        oldClan.approve_status = payload.status;
    },
};
export default {
    state,
    actions,
    mutations,
    getters
};
